<template>
  <div>
    <el-breadcrumb separator-icon="ArrowRight" style="margin: 16px">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>员工信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin: 15px; min-height: calc(100vh - 111px)">
      <div>
        <!--    功能区-->
        <div style="margin: 10px 0">
          <!--    搜索区-->
          <div style="margin: 10px 0">
            <el-input v-model="search" clearable placeholder="请输入姓名" prefix-icon="Search"
                      style="width: 20%"/>
            <el-button icon="Search" style="margin-left: 5px" type="primary" @click="load"></el-button>
            <el-button icon="refresh-left" style="margin-left: 10px" type="default" @click="reset"></el-button>
            <div style="float: right">
              <el-tooltip content="添加" placement="top">
                <el-button icon="plus" style="width: 50px" type="primary" @click="add"></el-button>
              </el-tooltip>
            </div>
          </div>
        </div>
        <!--    表格-->
        <el-table v-loading="loading" :data="tableData" border max-height="705" style="width: 100%">
          <el-table-column label="#" type="index"/>
          <el-table-column label="账号" prop="username" sortable/>
          <el-table-column label="姓名" prop="name"/>
          <el-table-column
              :filter-method="filterTag"
              :filters="[
              { text: '男', value: '男' },
              { text: '女', value: '女' },
            ]"
              filter-placement="bottom-end"
              label="性别"
              prop="gender"
          />
          <el-table-column label="年龄" prop="age" sortable/>
          <el-table-column label="手机号" prop="phoneNum"/>
          <el-table-column :show-overflow-tooltip="true" label="邮箱" prop="email"/>
          <el-table-column
              :filter-method="filterTag"
              :filters="[
              { text: '分析师', value: '分析师' },
              { text: '设计师', value: '设计师' },
              { text: '写手', value: '写手' },
            ]"

              filter-placement="bottom-end"
              label="身份"
              prop="identity"/>
          <el-table-column :show-overflow-tooltip="true" label="等级"
                           :filter-method="filterTag"
                           :filters="[
              { text: '标准设计师', value: '标准设计师' },
              { text: '精美设计师', value: '精美设计师' },
              { text: '高端设计师', value: '高端设计师' },
              { text: '首席设计师', value: '首席设计师' },
              { text: '优秀写手', value: '优秀写手' },
              { text: '专业写手', value: '专业写手' },
              { text: '金牌写手', value: '金牌写手' },
              { text: '中级分析师', value: '中级分析师' },
              { text: '高级分析师', value: '高级分析师' },
              ]"
                           filter-placement="bottom-end"
                           prop="level"/>
          <el-table-column :show-overflow-tooltip="true" label="支付宝账号" prop="zhifubao"/>
          <el-table-column :show-overflow-tooltip="true" label="银行卡号" prop="yhnumber"/>
          <!--      操作栏-->
          <el-table-column label="操作" width="130px">
            <template #default="scope">
              <el-button icon="Edit" type="primary" @click="handleEdit(scope.row)"></el-button>
              <el-popconfirm title="确认删除？" @confirm="handleDelete(scope.row.username)">
                <template #reference>
                  <el-button icon="Delete" type="danger"></el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!--分页-->
        <div style="margin: 10px 0">
          <el-pagination
              v-model:currentPage="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20]"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <div>
          <!--      弹窗-->
          <el-dialog v-model="dialogVisible" title="操作" width="30%" @close="cancel">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">
              <el-form-item label="账号" prop="username">
                <el-input v-model="form.username"  style="width: 80%"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input v-model="form.password" :disabled="disabled" :show-password="showpassword"
                          style="width: 80%"></el-input>
                <el-tooltip content="修改密码" placement="right">
                  <el-icon :style="editDisplay" size="large" style="margin-left: 5px; cursor: pointer"
                           @click="EditPass">
                    <edit/>
                  </el-icon>
                </el-tooltip>
              </el-form-item>
              <el-form-item :style="display" label="确认密码" prop="checkPass">
                <el-input v-model="form.checkPass" :show-password="showpassword" style="width: 80%"
                ></el-input>
              </el-form-item>
              <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" style="width: 80%"></el-input>
              </el-form-item>
              <el-form-item label="年龄" prop="age">
                <el-input v-model="form.age" style="width: 80%"
                ></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="gender">
                <el-radio v-model="form.gender" label="男">男</el-radio>
                <el-radio v-model="form.gender" label="女">女</el-radio>
              </el-form-item>
              <el-form-item label="手机号" prop="phoneNum">
                <el-input v-model.number="form.phoneNum" style="width: 80%"
                ></el-input>
              </el-form-item>
              <el-form-item label="身份证号" prop="email">
                <el-input v-model="form.email" style="width: 80%"></el-input>
              </el-form-item>
              <el-form-item label="身份" prop="identity">
                <el-radio v-model="form.identity" label="设计师">设计师</el-radio>
                <el-radio v-model="form.identity" label="写手">写手</el-radio>
                <el-radio v-model="form.identity" label="分析师">分析师</el-radio>
              </el-form-item>
              <el-form-item label="等级" prop="level">
                <el-radio v-model="form.level" label="标准设计师">标准设计师</el-radio>
                <el-radio v-model="form.level" label="精美设计师">精美设计师</el-radio>
                <el-radio v-model="form.level" label="高端设计师">高端设计师</el-radio>
                <el-radio v-model="form.level" label="首席设计师">首席设计师</el-radio>
                <el-radio v-model="form.level" label="优秀写手">优秀写手</el-radio>
                <el-radio v-model="form.level" label="专业写手">专业写手</el-radio>
                <el-radio v-model="form.level" label="金牌写手">金牌写手</el-radio>
                <el-radio v-model="form.level" label="中级分析师">中级分析师</el-radio>
                <el-radio v-model="form.level" label="高级分析师">高级分析师</el-radio>
              </el-form-item>
              <el-form-item label="支付宝账号" prop="zhifubao">
                <el-input v-model="form.zhifubao" style="width: 80%"></el-input>
              </el-form-item>
              <el-form-item label="银行卡号" prop="yhnumber">
                <el-input v-model="form.yhnumber" style="width: 80%"></el-input>
              </el-form-item>

            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script src="@/assets/js/StuInfo.js"></script>